<template>
  <section id="news_info">
    <div class="w1400">

      <small class="page_bread">
        首頁 - 最新消息 -
        <span
          class="txt-gray"
          v-if="newsData">
            {{newsData.title}}
        </span>
        <span
          class="txt-gray"
          v-else>
            查無文章
        </span>
      </small>

      <div
        class="info_box"
        ref="info_box"
        v-if="newsData">
          <div class="title_part">
            <div
              class="title_inner"
              ref="title_inner"
              :class="[{
                fixed: ifTitleFixed,
                ab: ifTitleBottom,
              }]">
                <div class="date">
                  <h2 class="h1 txt-theme">{{conversionDate(newsData.publish_date)[2]}}</h2>
                  <small>
                    {{conversionDate(newsData.publish_date)[0]}}年
                    {{conversionDate(newsData.publish_date)[1]}}月
                    <span>
                      {{conversionDate(newsData.publish_date)[2]}}號
                    </span>
                  </small>
                </div>
                <h4 class="txt-bold">{{newsData.title}}</h4>
            </div>
          </div>

          <div class="info_part">
            <figure
              :style="{
                backgroundImage: `url('https://kirinapi.howdesign.com.tw/${newsData.pic.path}')`
              }">
            </figure>

            <div class="content">
              <p
                v-html="newsData.content">
              </p>
            </div>
          </div>
      </div>

      <div
        class="main_padding-t"
        v-else>
          <h3 class="txt-gray txt-center">
            查無文章
          </h3>
      </div>

      <div class="txt-right">
        <div
          class="return"
          @click="$router.go(-1)">
            <h6 class="txt-up">Return</h6>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import requestApi from '@/lib/http/index';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'NewsInfo',
  mixins: [titleMixin],
  props: ['id'],
  components: {
  },
  data() {
    return {
      newsData: null,
      ifTitleFixed: false,
      ifTitleBottom: false,
    };
  },
  computed: {
    ...mapState([
      'scrollDistence',
    ]),
  },
  watch: {
    scrollDistence() {
      if (this.newsData) {
        if (this.scrollDistence > this.$refs.info_box.offsetTop - 50) {
          this.ifTitleFixed = true;
        } else {
          this.ifTitleFixed = false;
        }

        if (
          this.scrollDistence + this.$refs.title_inner.clientHeight
          > this.$refs.info_box.offsetTop + this.$refs.info_box.clientHeight - 55
        ) {
          this.ifTitleBottom = true;
        } else {
          this.ifTitleBottom = false;
        }
      }
    },
  },
  methods: {
    async getData() {
      const result = await requestApi(
        'news.index',
        { limit: 1, filter: { _id: this.id } },
      );
      console.log(result);

      if (result.entries.length > 0) {
        this.newsData = { ...result.entries[0] };
      }
    },
    conversionDate(date) {
      const d = date.split('-');
      return d;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style
  scoped
  src="@/assets/scss/news.scss"
  lang="scss">
</style>
